.App {
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  min-height: 100vh;
}

.dark-mode {
  background-color: #0a1f2d;
  color: white;
}

.light-mode {
  background-color: white;
  color: #0a1f2d;
}

.App-header {
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 1.5vmin);
  padding: 20px;
}

.newsletter-input:focus {
  outline: none;
}

.light-mode .newsletter-input::placeholder {
  color: #0a1f2d;
}

.dark-mode .newsletter-input::placeholder {
  color: white;
}

.toggle-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 8px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.toggle-button:hover {
  transform: scale(1.1);
}

.header-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.header-title h1 {
  font-size: clamp(48px, 10vw, 96px);
  font-weight: 100;
  margin: 0;
  letter-spacing: 0.02em;
  font-family: 'Libre Baskerville', serif;
  line-height: 0.8;
}

.xr-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: clamp(45px, 8vw, 65px);
  height: clamp(30px, 6vw, 40px);
  border: 1.5px solid #ffcc4a;
  color: #ffcb4a;
  font-weight: 300;
  font-size: clamp(20px, 4vw, 30px);
  border-radius: 10px;
  position: relative;
  top: -15px;
  font-family: 'Sofia Pro', sans-serif;
  line-height: 1;
  padding-top: 4px;
}

.newsletter-input {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ffcb4a;
  background-color: transparent;
  color: inherit;
  font-family: 'Sofia Pro', sans-serif;
  width: min(400px, 90vw);
  box-sizing: border-box;
}

.newsletter-button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #ffcb4a;
  border: none;
  cursor: pointer;
  font-family: 'Sofia Pro', sans-serif;
  white-space: nowrap;
  color:#0a1f2d
}

.typing-text {
  font-size: clamp(18px, 4vw, 24px);
  font-weight: 500;
  color: #ffcb4a;
  white-space: pre-wrap;
  word-break: break-word;
  text-align: center;
  max-width: min(70%, 600px);
  margin: 0 auto;
  position: relative;
  line-height: 1.3;
}

.typing-text::after {
  content: '';
  position: absolute;
  height: 1em;
  border-right: 1px solid #ffcb4a;
  animation: blink 0.7s step-end infinite;
}

@keyframes blink {
  from, to { border-color: transparent }
  50% { border-color: #ffcb4a }
}

.info-text {
  margin-top: 50px;
}

.highlighted-text {
  font-size: clamp(18px, 4vw, 24px);
  font-weight: 500;
  margin: 10px 0;
  font-family: 'Sofia Pro', sans-serif;
}

form {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Sofia Pro', sans-serif;
}

h2 {
  font-family: 'Sofia Pro', sans-serif;
  font-weight: 200;
  font-size: clamp(24px, 5vw, 33px);
  margin-top: 30px;
  letter-spacing: 3px;
  padding: 0 10px;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
  word-spacing: 5px;
}

p {
  font-family: 'Sofia Pro', sans-serif;
  font-weight: 300;
  font-size: clamp(14px, 3vw, 16px);
  line-height: 1.6;
  max-width: min(90%, 500px);
  margin: 0 auto;
  text-align: center;
}

.submit-status {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  font-family: 'Sofia Pro', sans-serif;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.submit-status.fade-out {
  opacity: 0;
}

.submit-status.success {
  background-color: rgba(0, 255, 0, 0.1);
  color: #00ff00;
}

.submit-status.error {
  background-color: rgba(255, 0, 0, 0.1);
  color: #ff0000;
}

@media (max-width: 600px) {
  .App {
    height: 100vh;
    overflow: hidden;
    position: fixed;
    width: 100%;
  }

  .App-header {
    height: 100vh;
    padding-top: 0;
    justify-content: flex-start;
    padding-top: 19vh;
  }

  h2 {
    white-space: normal;
    max-width: min(450px, 90vw);
  }

  .highlighted-text {
    margin: 1vh 0;
  }

  form {
    flex-direction: column;
    margin-top: 5vh;
    gap: 2vh;
    padding-bottom: env(safe-area-inset-bottom);
  }
  
  .newsletter-input,
  .newsletter-button {
    width: 100%;
  }
  
  .toggle-button {
    top: 10px;
    right: 10px;
  }
  
  .submit-status {
    margin-top: 15px;
  }
}